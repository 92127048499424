.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* Pedigree */
.circle {
  position: relative;
  width: 200px;
  height: 200px;
  border: 2px solid whitesmoke;
  border-radius: 50%;
  background-color: white;
  opacity: 0.5;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.circle:hover {
  background-color: white;
  opacity: 0.75;
}

.circle.clicked {
  animation: breathe 2s infinite;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  opacity: 1;
}

.dot1 {
  top: 20px;
}

.dot2 {
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
}

.dot3 {
  top: calc(100% - 20px);
}

/* Animation */
@keyframes breathe {
  0% {
    transform: scale(0.8);
    opacity: 0.75;
  }
  50% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.75;
  }
}
